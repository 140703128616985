import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@organisms/Layout'
import styled, { css } from '@xstyled/styled-components'
import { th, breakpoints } from '@xstyled/system'
import HTMLContent from '@atoms/HTMLContent'
import { Container } from '@atoms/Grid'
import transformMDtoHTML from '@utils/transformMDtoHTML'

const Page = ({ data }) => {
  const { html, frontmatter } = data.page
  const { meta } = frontmatter
  return (
    <Layout
      hamburgerMenu
      title={meta.title || ''}
      description={meta.description || ''}
      buttonColor="dark"
    >
      <S.Main>
        <S.ContainerPageSingle>
          {html && (
            <S.Content
              dangerouslySetInnerHTML={{
                __html: transformMDtoHTML(html),
              }}
            />
          )}
        </S.ContainerPageSingle>
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox``

S.ContainerPageSingle = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-weight: ${th('fontWeights.bold')};
    line-height: 1.1;
    margin-bottom: 70;
    ${breakpoints({
      xs: css`
        font-size: 24;
        letter-spacing: -0.02em;
      `,
      md: css`
        font-size: 72;
      `,
    })}
  }
  ${breakpoints({
    xs: css`
      width: 100%;
      margin-top: 120;
      padding-top: 40;
      padding-bottom: 100;
    `,
    md: css`
      max-width: 588;
      margin-top: 130;
      padding-top: 122;
      padding-bottom: 130;
    `,
    lg: css`
      max-width: 792;
    `,
  })}
`

S.Content = styled(HTMLContent)`
  width: 100%;
  ${breakpoints({
    xs: css`
      font-size: 16;
    `,
    md: css`
      font-size: 19;
    `,
  })}
`

export default Page

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    page: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        meta {
          description
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
